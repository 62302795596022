import React from "react";
import Footer from "../components/Footer";

const RefundAndCancellation = () => {
  return (
    <>
      <div className="mt-28 mb-10 lg:mx-40 mx-8 lg:p-12 p-8 shadow-2xl">
        <h1 className="text-2xl font-medium">Refund and Cancellation Policy</h1>
        <h2 className="mt-2">
        Committed to Quality, No Refunds Offered
        </h2>
        <h1 className="text-2xl font-medium mt-8">Final Order Confirmation:</h1>
        <h2 className="mt-2">
          Once your order is confirmed, we cannot accept cancellations.
        </h2>
        
        <h1 className="text-2xl font-medium mt-8">Third-Party Commitment</h1>
        <h2 className="mt-2">
        As a third-party service provider, we allocate resources based on your confirmation, making last-minute changes difficult.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Dedicated Resources</h1>
        <h2 className="mt-2">
        Your order triggers a commitment of time and effort from our team.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Modifications</h1>
        <h2 className="mt-2">
        If you need to adjust your order, please contact us as soon as possible. We’ll do our best to accommodate your request.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Efficiency Assurance</h1>
        <h2 className="mt-2">
        This policy is designed to ensure smooth operations and high-quality service for all clients.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Thank You for Your Understanding</h1>
        <h2 className="mt-2">
        We appreciate your support and understanding of our cancellation policy.
        </h2>


      </div>

      <Footer />
    </>
  );
};

export default RefundAndCancellation;
