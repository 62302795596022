import React, { useEffect, useState, useRef } from "react";
import logo from "../assets/logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, NavLink } from "react-router-dom";

const Header2 = () => {
  const [sms, setSms] = useState(false);
  const [email, setEmail] = useState(false);
  const [web, setWeb] = useState(false);
  const [other, setOther] = useState(false);
  const [miniSMS, setMiniSMS] = useState(false);
  const [miniWeb, setMiniWeb] = useState(false);
  const [miniEmail, setMiniEmail] = useState(false);
  const [miniOther, setMiniOther] = useState(false);

  const [anchorE1, setAnchorE1] = useState(null);
  const open1 = Boolean(anchorE1);
  const handleClick1 = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorE1(null);
  };

  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const [anchorE3, setAnchorE3] = useState(null);
  const open3 = Boolean(anchorE3);
  const handleClick3 = (event) => {
    setAnchorE3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorE3(null);
  };

  const [anchorE4, setAnchorE4] = useState(null);
  const open4 = Boolean(anchorE4);
  const handleClick4 = (event) => {
    setAnchorE4(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorE4(null);
  };

  // SCROLL TO HIDE OR SHOW HEADER START
  const [header, setHeader] = useState(false);
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.location.pathname === "/") {
        if (window.scrollY > 80) {
          setHeader(true);
        } else {
          setHeader(false);
        }
      } else setHeader(true);

      if (window.location.pathname !== "/") {
        setHeader(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [window.location.pathname]);
  // SCROLL TO HIDE OR SHOW HEADER END

  return (
    <div
      className={`header lg:px-20 px-5 z-50 bg-white shadow-md ${
        header ? " bg-white py-4 shadow-md" : "py-6 "
      }`}
    >
      <div className="lg:flex grid  items-center relative">
        {/* <h1 className="text-white font-bold text-2xl w-[150px]">SMS ROOT</h1> */}
        <Link to="/">
          <img src={logo} alt="logo" className="md:w-32 w-28  " />
        </Link>
        <h1
          className="text-black lg:hidden text-xl absolute top-0 right-4 cursor-pointer "
          onClick={() => {
            setMenu(!menu);
          }}
        >
          &#9776;
        </h1>
        <div
          className={`lg:flex flex-col lg:flex-row relative lg:items-center gap-4 text-black lg:justify-end w-full ${
            menu ? "flex bg-white " : "hidden"
          }`}
        >
          {/* <Link to="/" className="text-start">
            Home
          </Link> */}
          <button className="hidden lg:block text-start" onClick={handleClick1}>
            SMS Services
            <i class="fa-solid fa-chevron-down pt-1 pl-1 text-sm"></i>
          </button>
          <button className="block lg:hidden text-start">
            SMS Services
            <i
              class="fa-solid fa-chevron-down float-right lg:hidden"
              onClick={() => setMiniSMS(!miniSMS)}
            ></i>
          </button>
          <div
            className={`pl-4 ${
              miniSMS === true ? "grid gap-2 lg:hidden" : "hidden"
            }`}
          >
            <Link to="/promotionalSms" onClick={() => setMenu(false)}>
              Promotionals SMS
            </Link>
            <Link to="/Transactional" onClick={() => setMenu(false)}>
              Transactional SMS
            </Link>
            <Link to="/VoiceSms" onClick={() => setMenu(false)}>
              Voice SMS
            </Link>
            <Link to="/otpSms" onClick={() => setMenu(false)}>
              OTP SMS
            </Link>
          </div>
          <button className="hidden lg:block text-start" onClick={handleClick2}>
            Website Design
            <i class="fa-solid fa-chevron-down pt-1 pl-1 text-sm"></i>
          </button>
          <button className="block lg:hidden text-start">
            Website Design
            <i
              class="fa-solid fa-chevron-down float-right lg:hidden"
              onClick={() => setMiniWeb(!miniWeb)}
            ></i>
          </button>
          <div
            className={`pl-4 ${
              miniWeb === true ? "grid gap-2 lg:hidden" : "hidden"
            }`}
          >
            <Link to="/web-design" onClick={() => setMenu(false)}>
              Web Design
            </Link>
            <Link to="/website-development" onClick={() => setMenu(false)}>
              Website Development
            </Link>
            <Link to="/logo-design" onClick={() => setMenu(false)}>
              Logo Design
            </Link>
            <Link to="/mailer-design" onClick={() => setMenu(false)}>
              Mailer Design
            </Link>
          </div>
          <button className="hidden lg:block text-start" onClick={handleClick3}>
            Email Marketing
            <i class="fa-solid fa-chevron-down pt-1 pl-1 text-sm"></i>
          </button>
          <button className="block lg:hidden text-start">
            Email Marketing
            <i
              class="fa-solid fa-chevron-down float-right lg:hidden"
              onClick={() => setMiniEmail(!miniEmail)}
            ></i>
          </button>
          <div
            className={`pl-4 ${
              miniEmail === true ? "grid gap-2 lg:hidden" : "hidden"
            }`}
          >
            <Link to="/promotional-email" onClick={() => setMenu(false)}>
              Email Marketing
            </Link>
            <Link to="/premium-email" onClick={() => setMenu(false)}>
              Email Marketing
            </Link>
          </div>
          <button className="hidden lg:block text-start" onClick={handleClick4}>
            Other Services
            <i class="fa-solid fa-chevron-down pt-1 pl-1 text-sm"></i>
          </button>
          <button className="block lg:hidden text-start">
            Other Services
            <i
              class="fa-solid fa-chevron-down float-right lg:hidden"
              onClick={() => setMiniOther(!miniOther)}
            ></i>
          </button>
          <div
            className={`pl-4 ${
              miniOther === true ? "grid gap-2 lg:hidden" : "hidden"
            }`}
          >
            <Link to="/ivr-services" onClick={() => setMenu(false)}>
              IVR Services
            </Link>
            <Link to="/" onClick={() => setMenu(false)}>
              PPC/SEO
            </Link>
            <Link to="/" onClick={() => setMenu(false)}>
              Leads Plan
            </Link>
            <Link to="/" onClick={() => setMenu(false)}>
              Social Media
            </Link>
          </div>
          <button className="text-start">
            <Link to="/contact" onClick={() => setMenu(false)}>
              Contact Us
            </Link>
          </button>
          <div className="lg:absolute bottom-7 flex lg:flex-row flex-col">
            <button className="text-start">
              <Link to="/Payments" onClick={() => setMenu(false)}>
                Payments
              </Link>
            </button>
            <a
              href="mailto:support@surajenterprise.com"
              className="border-r border-gray11 px-3"
            >
              support@surajenterprise.com
            </a>
            <a href="tel:9912340901" className="px-3">
              9912340901
            </a>
          </div>
        </div>
      </div>
      <div className="absolute right-0 lg:top-48 top-14">
        <Link to="https://wa.me/+919912340901" target="_blank">
          <i class="fa-brands fa-whatsapp font-medium text-2xl text-[#00b731] bg-white px-3 py-1 rounded-tl rounded-bl hover:text-[#045cb4] shadow-lg"></i>
        </Link>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorE1}
        open={open1}
        onClose={handleClose1}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose1}>
          <Link to="/promotionalSms"> Promotionals SMS </Link>
        </MenuItem>
        <MenuItem onClick={handleClose1}>
          <Link to="/transactional"> Transactional SMS </Link>
        </MenuItem>
        <MenuItem onClick={handleClose1}>
          <Link to="/VoiceSms"> Voice SMS </Link>
        </MenuItem>
        <MenuItem onClick={handleClose1}>
          <Link to="/otpSms">OTP SMS</Link>
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorE2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose2}>
          <Link to="/web-design">Web Design</Link>
        </MenuItem>
        <MenuItem onClick={handleClose2}>
          <Link to="/website-development">Website Development</Link>
        </MenuItem>
        <MenuItem onClick={handleClose2}>
          <Link to="/logo-design">Logo Design</Link>
        </MenuItem>
        <MenuItem onClick={handleClose2}>
          <Link to="/mailer-design">Mailer Design</Link>
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorE3}
        open={open3}
        onClose={handleClose3}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose3}>
          <Link to="/promotional-email">Email Marketing</Link>
        </MenuItem>
        <MenuItem onClick={handleClose3}>
          <Link to="/premium-email">Email Marketing</Link>
        </MenuItem>
      </Menu>

      <Menu
        id="basic-menu"
        anchorEl={anchorE4}
        open={open4}
        onClose={handleClose4}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose4}>
          <Link to="/ivr-services">IVR Services</Link>
        </MenuItem>
        <MenuItem onClick={handleClose4}>PPC/SEO</MenuItem>
        
      </Menu>
    </div>
  );
};

export default Header2;

function SMS() {
  return (
    <div className="hidden lg:grid top-[90px] w-full fixed bg-white shadow-md left-0 lg:px-20 px-8 py-8">
      <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 flex flex-col">
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Promotionals SMS</h1>
          <div>
            <Link to="/promotionalSms" className="cursor-pointer">
              Promotional SMS DND
            </Link>
          </div>
          <div>
            <h4>Promotional SMS NON-DND</h4>
          </div>
          <div>
            <h4>GSM SMS Service</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Transactional SMS</h1>
          <div>
            <h4>Transactional SMS</h4>
          </div>
          <div>
            <h4>Notification SMS</h4>
          </div>
          <div>
            <h4>Dynamic SMS</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Voice SMS</h1>
          <div>
            <h4>Voice Call (Answer)</h4>
          </div>
          <div>
            <h4>Voice Call (Submission)</h4>
          </div>
          <div>
            <h4>Voice Call (15sec Duration)</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">OTP SMS</h1>
          <div>
            <h4>OTP Call SMS</h4>
          </div>
          <div>
            <h4>Transaction SMS (API)</h4>
          </div>
          <div>
            <h4>Promotional SMS (API)</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

function Website() {
  return (
    <div className="hidden lg:grid top-[90px] w-full fixed bg-white shadow-md left-0 lg:px-20 px-8 py-8">
      <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 flex flex-col">
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Web Design</h1>
          <div>
            <h4>Static</h4>
          </div>
          <div>
            <h4>Dunamic</h4>
          </div>
          <div>
            <h4>Wordpress</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Website Development</h1>
          <div>
            <h4>E-Commerce</h4>
          </div>
          <div>
            <h4>E-Commerce Customize</h4>
          </div>
          <div>
            <h4>OpenCart</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Logo Design</h1>
          <div>
            <h4>Logo Design</h4>
          </div>
          <div>
            <h4>Responsive Logo</h4>
          </div>
          <div>
            <h4>3D Logo</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Mailer Design</h1>
          <div>
            <h4>Mailer Design</h4>
          </div>
          <div>
            <h4>Responsive Maker</h4>
          </div>
          <div>
            <h4>Mailer Re-Design</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

function Email() {
  return (
    <div className="hidden lg:grid top-[90px] w-full fixed bg-white shadow-md left-0 lg:px-20 px-8 py-8">
      <div className="lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 flex flex-col">
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Email Marketing</h1>
          <div>
            <h4>Promotional Email</h4>
          </div>
          <div>
            <h4>SMTP Server</h4>
          </div>
          <div>
            <h4>Mailer Design</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Email Marketing</h1>
          <div>
            <h4>Amazon SES</h4>
          </div>
          <div>
            <h4>Mail Grid/Chimp Services</h4>
          </div>
          <div>
            <h4>Google Mail (G-Suite)</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Email Marketing</h1>
          <div>
            <h4>Microsoft Office 365</h4>
          </div>
          <div>
            <h4>Premium Business Mail</h4>
          </div>
          <div>
            <h4>Zoho Business Mail</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

function Other() {
  return (
    <div className="hidden lg:grid top-[90px] w-full fixed bg-white shadow-md left-0 lg:px-20 px-8 py-8">
      <div className="lg:grid lg:grid-cols-4 md:grid md:grid-cols-2 flex flex-col">
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">IVR Services</h1>
          <div>
            <h4>Toll Free</h4>
          </div>
          <div>
            <h4>IVR Number</h4>
          </div>
          <div>
            <h4>Miss Call Alert</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">PPC/SEO</h1>
          <div>
            <h4>Google Adwords</h4>
          </div>
          <div>
            <h4>Google Analytics</h4>
          </div>
          <div>
            <h4>SEO Services</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Leads Plan</h1>
          <div>
            <h4>SMS Leads</h4>
          </div>
          <div>
            <h4>Email Leads</h4>
          </div>
          <div>
            <h4>Facebook Leads</h4>
          </div>
        </div>
        <div className="gap-4 grid mx-auto">
          <h1 className="text-lg font-medium">Social Media</h1>
          <div>
            <h4>Facebook Promotion</h4>
          </div>
          <div>
            <h4>Youtube Promotion</h4>
          </div>
          <div>
            <h4>Traffic Generator</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
